const crudServiceMobileMixin = {
  methods: {
    postMobile (url, model) {
      return this.$axiosApiMobile.post(url, model)
    },
    deleteMobile (url, id) {
      return this.$axiosApiMobile.delete(url + '/' + id)
    },
    getMobile (url, params) {
      return this.$axiosApiMobile.get(url, params)
    },
    getByIdMobile (url, id) {
      return this.$axiosApiMobile.get(url + '/' + id)
    },
    putMobile (url, model) {
      return this.$axiosApiMobile.put(url, model)
    }
  }
}
export default crudServiceMobileMixin
