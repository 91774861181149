<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="data"
      :page.sync="page"
      :footer-props="footerProps"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :hide-default-footer="withoutPagination"
      @page-count="pageCount = $event"
    >
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </v-data-table>
    <div class="text-center" v-if="!withoutPagination">
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'

export default {
  name: 'DataTable',
  props: {
    url: {
      default: '',
      type: String
    },
    mobile: {
      default: false,
      type: Boolean
    },
    withoutPagination: {
      default: false,
      type: Boolean
    },
    params: {
      default: () => {
      },
      type: Object
    },
    headers: {
      default: () => [],
      type: Array
    }
  },
  mixins: [crudServiceMixin, crudServiceMobileMixin],
  data () {
    return {
      page: 1,
      pageCount: 0,
      total: 0,
      data: [],
      loading: true,
      options: {
        itemsPerPage: 20
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 20, 50, 100, 200]
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    getDataFromApi (customPage = null) {
      const { page, itemsPerPage } = this.options
      this.loading = true
      let callback = null

      if (this.mobile) {
        callback = this.getMobile(this.url, {
          params: {
            ...this.params,
            pageSize: itemsPerPage,
            page: customPage || page
          }
        })
      } else {
        callback = this.get(this.url, {
          params: {
            ...this.params,
            pageSize: itemsPerPage,
            page: customPage || page
          }
        })
      }

      callback
        .then(response => {
          this.data = response.data.data
          this.total = response.data.total
          this.page = customPage || page
          this.loading = false
        })
        .catch(() => {
          this.data = []
          this.total = 0
          this.loading = false
        })
    }
  }
}
</script>
